import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components/macro';

import {
  CalculatorBoxes,
  ContactForm,
  ContentBlock,
  ContentElement,
  Headline,
  Text,
} from '../components';

import {
  black,
  border,
  contentWidthDesktop,
  mediaMobile,
} from '../utils/sharedStyles';

import { bigBoxes, smallBoxes } from '../data';

const initialSelection = bigBoxes
  .map(box => ({
    headline: box.headline,
    item: box.items[0],
  }))
  .concat(
    smallBoxes.map(box => ({
      headline: box.headline,
      item: box.items[0],
    }))
  );

const Amount = ({ amount }) => (
  <svg
    version="1.1"
    viewBox="0 0 210 210"
    style={{ enableBackground: 'new 0 0 210 210' }}
  >
    <text
      transform="matrix(1 0 0 1 41.6235 120.8162)"
      style={{ fill: '#FF997C', font: 'normal normal bold 51px/26px Heebo' }}
    >
      &#8364;{amount}
    </text>
    <path
      style={{ fill: '#FF997C' }}
      d="M210,105c0-11.2-1.8-22-5-32.1c0,0,0,0,0,0V8c0-1.7-1.3-3-3-3h-64.9c0,0,0,0,0,0C127,1.8,116.2,0,105,0
	C93.8,0,83,1.8,72.9,5c0,0,0,0,0,0H8C6.3,5,5,6.3,5,8v64.9c0,0,0,0,0,0C1.8,83,0,93.8,0,105c0,11.2,1.8,22,5,32.1V202
	c0,1.7,1.3,3,3,3h64.9c0,0,0,0,0,0c10.1,3.2,20.9,5,32.1,5c11.2,0,22-1.8,32.1-5c0,0,0,0,0,0H202c1.7,0,3-1.3,3-3v-64.9
	C208.2,127,210,116.2,210,105z M145.3,8H202v56.7c0,0,0,0,0,0C191.4,39.1,170.9,18.6,145.3,8C145.3,8,145.3,8,145.3,8z M8,64.7V8
	h56.7c0,0,0,0,0,0C39.1,18.6,18.6,39.1,8,64.7C8,64.7,8,64.7,8,64.7z M64.7,202H8v-56.7c0,0,0,0,0,0C18.6,170.9,39.1,191.4,64.7,202
	C64.7,202,64.7,202,64.7,202z M3,105C3,48.8,48.8,3,105,3s102,45.8,102,102c0,56.2-45.8,102-102,102S3,161.2,3,105z M202,145.3V202
	h-56.7c0,0,0,0,0,0C170.9,191.4,191.4,170.9,202,145.3C202,145.3,202,145.3,202,145.3z"
    />
  </svg>
);

const StickyContainer = styled.div`
  display: flex;
  align-items: start;
`;

const Grid = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin: 1rem 0;

  @media (max-width: ${contentWidthDesktop}) {
    grid-template-columns: 1fr 1fr;
    width: 66%;
  }

  @media ${mediaMobile} {
    grid-template-columns: 1fr;
    width: 60%;
  }
`;

const Item = styled.div`
  align-self: start;
  width: 100%;

  @media (max-width: ${contentWidthDesktop}) {
    display: none;
  }
`;

const AmountWrapperMobile = styled.div`
  display: none;
  width: 33%;
  position: sticky;
  top: calc(100vh - ${({ height }) => height}px);
  margin-top: 10px;
  padding-left: 1.2rem;

  @media (max-width: ${contentWidthDesktop}) {
    display: inline-block;
  }

  @media ${mediaMobile} {
    width: 40%;
    padding-left: 0.5rem;
  }
`;

const StyledLink = styled(Link)`
  color: ${black};
`;

const Prices = () => {
  const [amount, setAmount] = useState(0);
  const [chosenOptions, setChosenOptions] = useState(initialSelection);
  const [amountContainerHeight, setContainerHeight] = useState(0);
  const amountRef = useRef(null);

  const positionAmount = () => {
    setContainerHeight(amountRef.current.offsetHeight);
  };

  useEffect(() => {
    positionAmount();

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', positionAmount);
      return () => {
        window.removeEventListener('resize', positionAmount);
      };
    }
  }, [amountRef]);

  const changeAmount = ({ price, chosenOption }) => {
    setAmount(lastPrice => lastPrice + price);
    setChosenOptions(() => {
      const newOptions = chosenOptions.map(option => {
        if (
          option.headline === chosenOption.headline &&
          option.item.id !== chosenOption.id
        )
          return chosenOption;
        return option;
      });
      return newOptions;
    });
  };

  return (
    <>
      <ContentBlock borderBottom={{ xs: border }} pb={8}>
        <ContentElement pb={5}>
          <Headline semiBold>Calculate your Mixing Service</Headline>
          <Text>
            Choose your required options to calculate my mixing service. Feel
            free to send a request for further informations. Of course, your
            material and requests will be kept 100% confidential! Total price is
            not including local taxes.
          </Text>
        </ContentElement>
        <StickyContainer>
          <Grid>
            <CalculatorBoxes boxes={bigBoxes} onItemClick={changeAmount} />
            <Item>
              <Amount amount={amount} />
            </Item>
            <CalculatorBoxes boxes={smallBoxes} onItemClick={changeAmount} />
          </Grid>
          <AmountWrapperMobile ref={amountRef} height={amountContainerHeight}>
            <Amount amount={amount} />
          </AmountWrapperMobile>
        </StickyContainer>
        <ContactForm chosenOptions={chosenOptions} />
      </ContentBlock>
      <ContentBlock my={8} py={0}>
        <Headline semiBold>Production &#38; Composition</Headline>
        <Text>
          All projects are very different and every artist has his own visions,
          hence producing and composing become very unique tasks. Giving you a
          standard price would never fit.
        </Text>
        <Text>
          But you are very welcome to{' '}
          <StyledLink to="/contact">
            <strong>get in touch</strong>
          </StyledLink>{' '}
          with me and describe your project. I'm eager to hear your story and
          support you musically!
        </Text>
      </ContentBlock>
    </>
  );
};

export default Prices;
